/* eslint-disable @typescript-eslint/no-namespace */

namespace FeatureFlags {
  export const enum Bob {
    ENABLE_WEB_ZERO_IHF = 'web_enable_zero_ihf',
    LOTUS_ENABLE_ZVIP_TOGGLE_CART = 'lotus_enable_zvip_toggle_cart',

    ENABLE_DJ_CATALOG = 'web_enable_dj_all_catalog',

    ENABLE_DATAJET_AUTOCOMPLETE = 'web_enable_datajet_autocomplete',

    LOTUS_ENABLE_IHF_ON_PACKAGE_HEADER = 'lotus_enable_ihf_on_package_header',
    LOTUS_ENABLE_CONTENTFUL_PERSONALISATION = 'lotus_enable_contentful_personalisation',

    LOTUS_REVIEW_TRANSLATION = 'lotus_review_translation',
    LOTUS_REVIEW_TRANSLATION_V2 = 'lotus_review_translation_v2',

    LOTUS_SONIC_CHECKOUT = 'lotus_sonic_checkout',

    LOTUS_EYEFITU_DISPLAY = 'lotus_eyefitu_display',

    LOTUS_ENABLE_BEST_PRICE_GUARANTEE = 'lotus_enable_best_price_guarantee',

    LOTUS_ENABLE_SOCIAL_PROOFING = 'lotus_enable_social_proofing',
    LOTUS_ENABLE_INTERACTIVE_JOURNEY = 'lotus_enable_interactive_journey',

    LOTUS_ENABLE_PDV_404 = 'web_enable_pdv_404',

    SHIPPING_SST_2024 = 'shipping_sst_2024',
    ZVIP_VOUCHER_VISIBILITY = 'web_zvip_voucher_visibility',

    LOTUS_SERIAL_RETURNER = 'lotus_serial_returner',
    ENABLE_HOMEPAGE_DJ_RECS = 'lotus_enable_homepage_datajet_recs',

    GIFT_CARD_LEAKAGE_PREVENTION = 'web_gc_leakage_prevention',
    LOTUS_REQUEST_EINVOICE = 'lotus_request_einvoice',
    GIFT_WITH_PURCHASE = 'web_gwp',

    CART_PROMOTION_PROMPT = 'web_cart_promo_prompt',
    LOTUS_ENABLE_ENRICHED_ATTRIBUTES = 'lotus_enable_enriched_attributes',
    LOTUS_ACCOUNT_DETAILS_REVAMP = 'lotus_account_details_revamp',

    LOTUS_OPTIMIZELY_CLIENT = 'lotus_optimizely_client',

    LOTUS_WALLET_CREDIT_UI_REVAMP = 'lotus_wallet_credit_ui_revamp',

    ENABLE_GOPAY_DESKTOP = 'enable_desktop_gopay',
    DESKTOP_GOPAY_TOKENIZATION_ENABLED = 'desktop_enable_gopay_tokenization',
  }

  export const enum Optimizely {
    ENABLE_DJ_CATALOG = 'web_enable_dj_all_catalog',

    LOTUS_EYEFITU_DISPLAY = 'lotus_eyefitu_display',

    LOTUS_ENABLE_BEST_PRICE_GUARANTEE = 'lotus_enable_best_price_guarantee',
    LOTUS_SOCIAL_PROOFING = 'lotus_social_proofing',

    LOTUS_PDV_SOMETHING_SIMILAR_DJ = 'lotus_pdv_something_similar_dj',

    CART_PROMOTION_PROMPT = 'web_cart_promo_prompt',
    ENABLE_HOMEPAGE_DJ_RECS = 'lotus_enable_homepage_datajet_recs',

    LOTUS_ENABLE_ENRICHED_ATTRIBUTES = 'lotus_enable_enriched_attributes',

    LOTUS_PRICE_DROP_TRACKER = 'lotus_price_drop_tracker',
  }

  export type BobFlag = `${Bob}`;
  export type OptimizelyFlag = `${Optimizely}`;
}

export default FeatureFlags;
