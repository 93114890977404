export const localStorageKeys = {
  DELIVERY_LOCATION: 'delivery_location',
  RECENT_SEARCH: 'recent_search',
  MEMBERSHIP_NOTIFICATION: 'membership_notification',
  VARIATIONS_TOOLTIP_COUNT: 'variations_tooltip',
  CART_STORE: 'cart_store',
  SIZE_GUIDE_ONBOARDING: 'size_guide_onboarding',
  HIDE_GIFT_PROMOTION_ONBOARDING: 'hide_gift_promotion_onboarding',
} as const;

export const clearLocalStorage = () => {
  Object.values(localStorageKeys).forEach((k: string) => {
    localStorage.removeItem(k);
  });
};

export const getCountVariationsOpenedTimes = () =>
  Number(localStorage.getItem(localStorageKeys.VARIATIONS_TOOLTIP_COUNT)) || 0;

export const getCountSizeGuideOpenedTimes = () =>
  Number(localStorage.getItem(localStorageKeys.SIZE_GUIDE_ONBOARDING)) || 0;

export const shouldHidePromotionOnboarding = () => {
  return localStorage.getItem(localStorageKeys.HIDE_GIFT_PROMOTION_ONBOARDING) === 'true';
};
