import { BobFeatureFlagConfigByRoute } from 'types/bob-feature-flags';
import FeatureFlags from './feature-flags';
import { Routes } from './routes';

const catalogConfig = {
  server: [
    FeatureFlags.Bob.ENABLE_DJ_CATALOG,
    FeatureFlags.Bob.LOTUS_ENABLE_BEST_PRICE_GUARANTEE,
    FeatureFlags.Bob.LOTUS_ENABLE_SOCIAL_PROOFING,
    FeatureFlags.Bob.GIFT_WITH_PURCHASE,
    FeatureFlags.Bob.LOTUS_ENABLE_ENRICHED_ATTRIBUTES,
  ],
  client: [],
};

const segmentConfig = {
  server: [
    FeatureFlags.Bob.LOTUS_ENABLE_CONTENTFUL_PERSONALISATION,
    FeatureFlags.Bob.ENABLE_HOMEPAGE_DJ_RECS,
  ],
};

export const FF_CONFIG_BY_ROUTE: BobFeatureFlagConfigByRoute = {
  [Routes.CART]: {
    client: [
      FeatureFlags.Bob.ENABLE_WEB_ZERO_IHF,
      FeatureFlags.Bob.LOTUS_ENABLE_ZVIP_TOGGLE_CART,
      FeatureFlags.Bob.LOTUS_ENABLE_IHF_ON_PACKAGE_HEADER,
      FeatureFlags.Bob.SHIPPING_SST_2024,
      FeatureFlags.Bob.GIFT_CARD_LEAKAGE_PREVENTION,
      FeatureFlags.Bob.CART_PROMOTION_PROMPT,
      FeatureFlags.Bob.LOTUS_SONIC_CHECKOUT,
      FeatureFlags.Bob.ZVIP_VOUCHER_VISIBILITY,
    ],
    server: [],
  },
  [Routes.WISHLIST]: {
    client: [],
    server: [FeatureFlags.Bob.GIFT_WITH_PURCHASE],
  },
  [Routes.CATALOG]: catalogConfig,
  [Routes.ERROR]: {},
  [Routes.HOMEPAGE]: segmentConfig,
  [Routes.PRODUCT]: {
    client: [FeatureFlags.Bob.LOTUS_EYEFITU_DISPLAY, FeatureFlags.Bob.ZVIP_VOUCHER_VISIBILITY],
    server: [
      FeatureFlags.Bob.ENABLE_DJ_CATALOG, // For PDV 404
      FeatureFlags.Bob.LOTUS_REVIEW_TRANSLATION_V2,
      FeatureFlags.Bob.LOTUS_ENABLE_BEST_PRICE_GUARANTEE,
      FeatureFlags.Bob.LOTUS_ENABLE_SOCIAL_PROOFING,
      FeatureFlags.Bob.LOTUS_ENABLE_PDV_404,
      FeatureFlags.Bob.GIFT_WITH_PURCHASE,
    ],
  },
  [Routes.ACCOUNT_ORDERS]: {
    client: [FeatureFlags.Bob.LOTUS_SERIAL_RETURNER, FeatureFlags.Bob.LOTUS_REQUEST_EINVOICE],
  },
  [Routes.ZIS]: {
    server: [
      FeatureFlags.Bob.ENABLE_DJ_CATALOG,
      FeatureFlags.Bob.LOTUS_ENABLE_BEST_PRICE_GUARANTEE,
      FeatureFlags.Bob.LOTUS_ENABLE_SOCIAL_PROOFING,
      FeatureFlags.Bob.GIFT_WITH_PURCHASE,
    ],
    client: [],
  },
  [Routes.SEARCH]: catalogConfig,
  [Routes.SEGMENT]: segmentConfig,
  [Routes.ACCOUNT]: {
    client: [
      FeatureFlags.Bob.ENABLE_GOPAY_DESKTOP,
      FeatureFlags.Bob.DESKTOP_GOPAY_TOKENIZATION_ENABLED,
      FeatureFlags.Bob.LOTUS_ACCOUNT_DETAILS_REVAMP,
      FeatureFlags.Bob.LOTUS_WALLET_CREDIT_UI_REVAMP,
    ],
  },

  // `common` key is used to fetch shared flags for all routes
  common: {
    server: [
      FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY,
      FeatureFlags.Bob.LOTUS_OPTIMIZELY_CLIENT,
    ],
    client: [FeatureFlags.Bob.ENABLE_DATAJET_AUTOCOMPLETE],
  },
};
