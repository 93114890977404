import { unstable_serialize as unstableSerialize } from 'swr';
import * as featureFlagApi from 'api/featureflag';
import FeatureFlags from 'constants/feature-flags';
import { RouteKey, Routes } from 'constants/routes';
import { BobFeatureFlagConfigByRoute, BobFeatureFlagConfigKey } from 'types/bob-feature-flags';
import { isAccountPage } from './page-type';

type FeatureFlagParams = {
  featureFlagConfig: BobFeatureFlagConfigByRoute;
  routeKey: BobFeatureFlagConfigKey;
  options?: {
    isServerOnly?: boolean;
    isClientOnly?: boolean;
  };
};

export const getFlagsByRouteKey = ({
  featureFlagConfig,
  routeKey,
  options = {
    isServerOnly: false,
    isClientOnly: false,
  },
}: FeatureFlagParams) => {
  const commonFlags = routeKey !== 'common' ? featureFlagConfig.common || {} : {};

  // shared flags for all customer pages
  const customerPagesFlags =
    routeKey && isAccountPage(routeKey) ? featureFlagConfig[Routes.ACCOUNT] || {} : {};

  const routeFlags = routeKey ? featureFlagConfig[routeKey] || {} : {};

  const { client: clientRouteFlags = [], server: serverRouteFlags = [] } = routeFlags;
  const { client: clientCommonFlags = [], server: serverCommonFlags = [] } = commonFlags;
  const { client: clientCustomerPagesFlags = [], server: serverCustomerPagesFlags = [] } =
    customerPagesFlags;

  if (options.isClientOnly) {
    return [...clientCommonFlags, ...clientRouteFlags, ...clientCustomerPagesFlags];
  }

  if (options.isServerOnly) {
    return [...serverCommonFlags, ...serverRouteFlags, ...serverCustomerPagesFlags];
  }

  return [
    ...clientCommonFlags,
    ...clientRouteFlags,
    ...serverCommonFlags,
    ...serverRouteFlags,
    ...clientCustomerPagesFlags,
    ...serverCustomerPagesFlags,
  ];
};

export const fetchBobFeatureFlags = async ({
  featureFlagConfig,
  routeKey,
  options = {
    isServerOnly: false,
    isClientOnly: false,
  },
}: FeatureFlagParams) => {
  const flags = getFlagsByRouteKey({ featureFlagConfig, routeKey, options });

  if (flags.length === 0) {
    return [];
  }

  const { data } = (await featureFlagApi.getEnabledFeatureFlags(flags)) || {};
  const { EnabledFeatures = [] } = data || {};

  return EnabledFeatures as FeatureFlags.BobFlag[];
};

export const isBobFeatureFlagEnabled = (
  checkList: FeatureFlags.BobFlag[],
  flagOrFlags: FeatureFlags.BobFlag | FeatureFlags.BobFlag[],
) => {
  if (Array.isArray(flagOrFlags)) {
    return flagOrFlags.every((flag) => checkList.includes(flag));
  }

  return checkList.includes(flagOrFlags);
};

export const isServerSideUsedFeatureFlag = ({
  featureFlagConfig,
  flagOrFlags,
  routeKey,
}: {
  featureFlagConfig: BobFeatureFlagConfigByRoute;
  flagOrFlags: FeatureFlags.BobFlag | FeatureFlags.BobFlag[];
  routeKey: BobFeatureFlagConfigKey;
}) => {
  if (!routeKey) {
    return false;
  }

  if (Array.isArray(flagOrFlags)) {
    return flagOrFlags.every((flag) => featureFlagConfig[routeKey]?.server?.includes(flag));
  }

  return featureFlagConfig[routeKey]?.server?.includes(flagOrFlags);
};

export const constructCacheKey = (key: string) => {
  return ['/v1/featureflag', key];
};

export const getSwrKeyByRoute = (
  routeKey: RouteKey | undefined,
  featureFlagConfig: BobFeatureFlagConfigByRoute,
) => {
  if (!routeKey) {
    return {
      server: null,
      client: null,
    };
  }

  const serverSwrKey = unstableSerialize(constructCacheKey(routeKey));
  const clientSwrKey = JSON.stringify(
    getFlagsByRouteKey({ routeKey, featureFlagConfig, options: { isClientOnly: true } }),
  );

  return {
    server: serverSwrKey,
    client: clientSwrKey,
  };
};
